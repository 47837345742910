import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";

const GraphDataindashboard = ({ graphData }) => {
  const [isDataAvailable, setIsDataAvailable] = useState(true);

  useEffect(() => {
    // Destructure graph data with default values if data is missing
    const {
      todayCarEntrycount = 0,
      todayCarExitscount = 0,
      todayBikeEntrycount = 0,
      todayBikeExitscount = 0,
    } = graphData || {}; // Fallback to an empty object if graphData is undefined

    // Check if data is zero for both car and bike
    const carDataAvailable = todayCarEntrycount > 0 || todayCarExitscount > 0;
    const bikeDataAvailable = todayBikeEntrycount > 0 || todayBikeExitscount > 0;

    setIsDataAvailable(carDataAvailable || bikeDataAvailable);

    // Vibrant, colorful palette for the pie chart
    const vibrantCarColors = ["#FF6347", "#FF8C00", "#FFD700", "#ADFF2F"];
    const vibrantBikeColors = ["#00BFFF", "#32CD32", "#8A2BE2", "#FF1493"];

    // Options for Car Entry/Exit Pie Chart (with default for no data)
    const carOptions = {
      series: carDataAvailable
        ? [todayCarEntrycount, todayCarExitscount]
        : [1, 1], // Default values to render a visible graph when no data
      chart: {
        type: "pie",
        height: 300,
      },
      labels: carDataAvailable ? ["Car Entry", "Car Exit"] : ["No Data"],
      colors: carDataAvailable ? vibrantCarColors : ["#FFB6C1", "#D3D3D3"], // Default colors for no data (lighter pink and gray)
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      annotations: carDataAvailable
        ? {}
        : {
            points: [
              {
                x: 0,
                y: 0,
                label: {
                  text: "No Data",
                  style: {
                    color: "#FF6347", // Red color for the "No Data" label
                    fontSize: "18px",
                    fontWeight: "bold",
                  },
                },
              },
            ],
          },
    };

    // Options for Bike Entry/Exit Pie Chart (with default for no data)
    const bikeOptions = {
      series: bikeDataAvailable
        ? [todayBikeEntrycount, todayBikeExitscount]
        : [1, 1], // Default values to render a visible graph when no data
      chart: {
        type: "pie",
        height: 300,
      },
      labels: bikeDataAvailable ? ["Bike Entry", "Bike Exit"] : ["No Data"],
      colors: bikeDataAvailable ? vibrantBikeColors : ["#FFB6C1", "#D3D3D3"], // Default colors for no data (lighter pink and gray)
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      annotations: bikeDataAvailable
        ? {}
        : {
            points: [
              {
                x: 0,
                y: 0,
                label: {
                  text: "No Data",
                  style: {
                    color: "#FF6347", // Red color for the "No Data" label
                    fontSize: "18px",
                    fontWeight: "bold",
                  },
                },
              },
            ],
          },
    };

    // Render Car Entry/Exit Chart
    const carChart = new ApexCharts(document.querySelector("#carChart"), carOptions);
    carChart.render();

    // Render Bike Entry/Exit Chart
    const bikeChart = new ApexCharts(document.querySelector("#bikeChart"), bikeOptions);
    bikeChart.render();

    // Clean up charts on component unmount
    return () => {
      carChart.destroy();
      bikeChart.destroy();
    };
  }, [graphData]); // Dependency to re-render charts when graphData changes

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div id="carChart" style={{ width: "45%" }}>
        {/* Car Chart will be rendered here */}
      </div>
      <div id="bikeChart" style={{ width: "45%" }}>
        {/* Bike Chart will be rendered here */}
      </div>
    </div>
  );
};

export default GraphDataindashboard;
