import React from "react";
import Header from "./Header";
import Dashboard1 from "./Dashboard1";
const Dashboard = () => {
  // alert("dashbord 1");
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="container-fluid">
        <div className="row">
          <Dashboard1 />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
