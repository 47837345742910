import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Form, Button } from "react-bootstrap";

const Header = ({ setOfficeId }) => {
  const [tenantName, setTenantName] = useState("");
  const [officeList, setOfficeList] = useState([]);

  useEffect(() => {
    fetchOffices();
  }, []);

  const fetchOffices = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, please log in.");
        return;
      }
      const response = await axios.get("http://localhost:8000/api/getoffice", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.office) {
        setOfficeList(response.data.office);
      } else {
        console.error("Error fetching offices:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching offices:", error.response?.data || error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tenantName) {
      console.log(tenantName);
      setOfficeId(tenantName); // Pass the selected office_id to the Dashboard
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div style={{ height: "80px", backgroundColor: "#FFFFFF", marginTop: "-8.5px" }}>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <h4 className="adminlogo">Welcome back, Skymark - Parking management system</h4>
            <img style={{ height: "70px" }} src="adminlogo.png" alt="Admin Logo" />
          </div>
        </div>
      </div>
      <div className="row mt-4">
  <div style={{ marginRight: "10px" }} className="col-md-12 d-flex align-items-end">
    <Form.Group controlId="formTenant" className="flex-grow-1" style={{ marginRight: "20px" }}>
      <Form.Label>Select Tenant</Form.Label>
      <Form.Control
        as="select"
        value={tenantName}
        onChange={(e) => setTenantName(e.target.value)}
      >
        <option value="">Select Tenant</option>
        {officeList.map((office, index) => (
          <option key={index} value={office.office_id}>
            {office.office_name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
    <Button onClick={handleSubmit} style={{ marginLeft: "10px" }}>
      Submit
    </Button>
  </div>
</div>

    </div>
  );
};

export default Header;
