import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  FaBars,
  FaHome,
  FaUser,
} from "react-icons/fa";
import { BiAnalyse } from "react-icons/bi";
import { IoMdPhonePortrait } from "react-icons/io";
import {
  AiFillBuild,
  AiOutlineLogout,
  AiTwotoneFileExclamation,
} from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./TenantSidebarMenu";
import Logo from './logo-bg.png'

const TenantSideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 430) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const routes = [
    {
      path: "/Tenant/dashboard",
      name: "Dashboard",
      icon: (
        <FaHome
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
    },
    {
      path: "/Tenant/Employee-management",
      name: "Employee Management",
      icon: (
        <FaUser
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
      subRoutes: [
        { path: "/Tenant/create-Employee", name: "Create User" },
        { path: "/Tenant/manage-Employee", name: "Manage User" },
      ],
    },
    {
      path: "/Tenant/tag",
      name: "Tag",
      icon: (
        <AiFillBuild
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
      subRoutes: [
        { path: "/Tenant/add-tag", name: "Add-Tag" },
        { path: "/Tenant/manage-tag", name: "Manage-Tag" },
      ],
    },
    {
      path: "/Tenant/reports",
      name: "Report",
      icon: (
        <AiTwotoneFileExclamation
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
    },
    {
      path: "/Tenant/signout",
      name: "Logout",
      icon: (
        <AiOutlineLogout
          className={`icon-dashboard ${!isOpen ? "icon-shift-left" : ""}`}
          style={{ color: "black" }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="main-container">
        <motion.div
          className={`sidebar ${isOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <img
                    style={{ marginLeft: "10px", width: "90%" }}
                    src={Logo}
                    className="img-thumbnail"
                    alt="Cinque Terre/"
                  ></img>
                </motion.h1>
              )}
            </AnimatePresence>
            <div className="bars">
              <FaBars
                onClick={toggleSidebar}
                style={{ color: "black", cursor: "pointer" }}
              />
            </div>
          </div>
          <section
            // style={{ fontSize: "23px" }}
            style={{ paddingLeft: "20px", fontSize: "23px" }}
            className="routes"
          >
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                    key={index}
                  />
                );
              }
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon-text-padding">
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                          style={{ color: "black", fontSize: "17px" }} // Apply black color to link text
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default TenantSideBar;
