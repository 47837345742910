import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { reorder } from "framer-motion";
const GraphDataindashboard = ({ graphData }) => {
  useEffect(() => {
    // Destructure graph data
    const {
      todayCarEntrycount,
      todayCarExitscount,
      todayBikeEntrycount,
      todayBikeExitscount,
    } = graphData;

    // Options for Car Entry/Exit Pie Chart
    const carOptions = {
      series: [todayCarEntrycount, todayCarExitscount],
      chart: {
        type: "pie",
        height: 300,
      },
      labels: ["Car Entry", "Car Exit"],
      colors: ["#008FFB", "#FF4560"],
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    // Options for Bike Entry/Exit Pie Chart
    const bikeOptions = {
      series: [todayBikeEntrycount, todayBikeExitscount],
      chart: {
        type: "pie",
        height: 300,
      },
      labels: ["Bike Entry", "Bike Exit"],
      colors: ["#00E396", "#775DD0"],
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    // Render Car Entry/Exit Chart
    const carChart = new ApexCharts(document.querySelector("#carChart"), carOptions);
    carChart.render();

    // Render Bike Entry/Exit Chart
    const bikeChart = new ApexCharts(document.querySelector("#bikeChart"), bikeOptions);
    bikeChart.render();

    // Clean up charts on component unmount
    return () => {
      carChart.destroy();
      bikeChart.destroy();
    };
  }, [graphData]); // Dependency to re-render charts when graphData changes

  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div id="carChart" style={{ width: "45%" }}>
        {/* Car Chart will be rendered here */}
      </div>
      <div id="bikeChart" style={{ width: "45%" }}>
        {/* Bike Chart will be rendered here */}
      </div>
    </div>
  );
};

export default GraphDataindashboard;
