// import "./App.css";
// import SideBar from "./components/Sidebar/SideBar";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Dashboard from "./pages/Dashboard/Dashboard";
// import Reports from "./pages/Report/Reports";
// import Signout from "./pages/Signout/Signout";
// import TicketsLiveLogs from "./pages/Tickets_Live_Logs/Tickets_Live_Logs";
// function App() {
//     return (
//       <Router>
//         <SideBar>
//           <Routes>
//             <Route path="/" element={<Dashboard />} />
//             <Route path="/ticket-live-logs" element={<TicketsLiveLogs/>} />
//             <Route path="/signout" element={<Signout />} />
//              <Route path="/Reports" element={<Reports />} />
//           </Routes>
//         </SideBar>
//       </Router>
//     );
//   }
// export default App;
import "./App.css";
import React, { useState, useEffect } from 'react';
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
 import Assignment from "./pages/Roll-Management/Assignpermision";
 import Createrole from "./pages/Roll-Management/Createrole";
 import Manageroll from "./pages/Roll-Management/Manageroll";
 import Managepermission from "./pages/Roll-Management/Managepermission";
 import Signout from "./pages/Signout/Signout";
import Login from "./pages/Login/Login";
import CreateTenant from './pages/Tenants/CreateTenant'
import ManageTenant from'./pages/Tenants/ManageTenant'
import Reports from './pages/Report/Report'
import Addadmin from "./pages/Admin/Addadmin";
import Manageadmin from "./pages/Admin/Manageadmin";

import TenantSidebar from "./components/TenantSidebar/TenantSidebar";
import TenantDashboard from './Tenant/Dashboard/Dashboard';
import AddEmployee from './Tenant/Employee/AddEmployee'
import ManageEmployee from './Tenant/Employee/ManageEmployee'
import Tag from './Tenant/Tag/AddTag'
import ManageTag from './Tenant/Tag/ManageTag'
import Report from './Tenant/Report/Report'
import TenantSignout from './Tenant/Signout/Signout'
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenChecked, setTokenChecked] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Set isLoggedIn to true if token exists
    setTokenChecked(true);  // Mark that token check is complete
  }, [isLoggedIn]); // Run only once when the component mounts

  if (!tokenChecked) {
    // You can render a loading indicator or any other UI here
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {

    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/dashboard" element={<Login setIsLoggedIn={setIsLoggedIn}  />} />
          <Route path="/signout" element={<Login />} />
          <Route path="/create-roll" element={<Login />} />
           <Route path="/asign-permission" element={<Login />} />
           <Route path="/manage-roll" element={<Login />} />  
           <Route path="/manage-Permission" element={<Login />} />
          <Route path="/reports" element={<Login />} />
          <Route path="/add-admin" element={<Login/>} />
          <Route path="/manage-admin" element={<Login/>} />

          <Route path="/create-tenant" element={<Login />} />
          <Route path="/manage-tanant" element={<Login />} />
        </Routes>
      </Router>
    )
  }
  else {
    const role_name = localStorage.getItem('role_name');
    if(role_name == 'Admin'){
      return (
        <Router>
          <SideBar>
            <Routes>
            <Route path="/" element={<Dashboard />} />

            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="/client" element={<ClientPage />} /> */}
               <Route path="/signout" element={<Signout />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/add-admin" element={<AddEmployee/>}/>
                <Route path="/manage-admin" element={<ManageEmployee/>}/>
                <Route path="/create-roll" element={<Createrole/>} />
                <Route path="/asign-permission" element={<Assignment/>} />
                <Route path="/manage-roll" element={<Manageroll/>} />
                <Route path="/manage-Permission" element={<Managepermission/>} />
                <Route path="/create-tenant" element={<CreateTenant />} />
                <Route path="/manage-tanant" element={<ManageTenant />} />
                <Route path="/Tenant/add-tag" element={<Tag />} />
                <Route path="/Tenant/manage-tag" element={<ManageTag />} />
            </Routes>
          </SideBar>
        </Router>
      );

    } else if(role_name == 'Client'){
      return (
        <Router>
          <TenantSidebar>
            <Routes>
              <Route path="/Tenant/dashboard" element={<TenantDashboard />} />
              <Route path="/Tenant/create-employee" element={<AddEmployee />} />
              <Route path="/Tenant/manage-employee" element={<ManageEmployee />} />
              <Route path="/Tenant/add-tag" element={<Tag />} />
              <Route path="/Tenant/manage-tag" element={<ManageTag />} />
              <Route path="/Tenant/reports" element={<Report/>} />
              <Route path="/Tenant/signout" element={<TenantSignout/>} />
            </Routes>
          </TenantSidebar>
        </Router>
      );
    }
  }
}
export default App;


