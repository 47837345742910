import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Button,
} from "react-bootstrap";
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";
import EditTenant from "./EditTenant";
import * as XLSX from "xlsx"; // Import the XLSX library

const ManageTenant = () => {
  const [tenants, setTenants] = useState([]);
  const [error, setError] = useState("");
  const [editingTenant, setEditingTenant] = useState(null); // State for edit mode
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          setError("Authorization token not found.");
          return;
        }

        const response = await axios.get("http://localhost:8000/api/getalloffices", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.status === "success") {
          console.log(response.data.office);
          setTenants(response.data.office || []);
        } else {
          setError(response.data.message || "Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching data.");
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (tenant) => {
    setEditingTenant(tenant); // Open edit mode with selected tenant's data
  };

  const handleDelete = async (tenantName) => {
    try {
      const response = await axios.post(
        "http://localhost:8000/api/delete-office",
        { company_name: tenantName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === "success") {
        alert("Tenant deleted successfully.");
        setTenants((prevTenants) => prevTenants.filter((tenant) => tenant.company_name !== tenantName));
      } else {
        alert(response.data.message || "Failed to delete tenant.");
      }
    } catch (error) {
      console.error("Error deleting tenant:", error);
      alert("An error occurred while deleting the tenant.");
    }
  };

  const handleSave = (updatedTenant) => {
    setTenants((prevTenants) =>
      prevTenants.map((tenant) =>
        tenant.o_id === updatedTenant.o_id ? updatedTenant : tenant
      )
    );
  };

  // Function to handle downloading data as an Excel file
  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tenants.map(tenant => ({
      "Tenant Name": tenant.company_name || "N/A",
      "Car Capacity": tenant.car_capacity || "N/A",
      "Bike Capacity": tenant.bike_capacity || "N/A",
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tenants");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "tenants_data.xlsx");
  };

  return (
    <Container fluid className="p-4" style={{ minHeight: "100vh" }}>
      {/* Button in the top-right corner, above the records */}
      <Row className="mb-3">
        <Col xs={12} className="d-flex justify-content-end">
          <Button
            variant="success"
            onClick={handleDownloadExcel}
          >
            Download as Excel
          </Button>
        </Col>
      </Row>

      <Row className="flex-grow-1" style={{ height: "calc(100vh - 200px)" }}>
        <Col xs={12}>
          <Card className="mt-3 flex-grow-1" style={{ height: "100%" }}>
            <Card.Header className="bg-white">
              <strong>TOP 100 RECORDS</strong>
            </Card.Header>
            <Card.Body className="d-flex flex-column">
              {error && <p className="text-danger">{error}</p>}
              <div style={{ overflowX: "auto" }}>
                <Table striped hover responsive className="mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tenant Name</th>
                      <th>Car Capacity</th>
                      <th>Bike Capacity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tenants.map((tenant, index) => (
                      <tr key={tenant.o_id}>
                        <td>{index + 1}</td>
                        <td>{tenant.company_name || "N/A"}</td>
                        <td>{tenant.car_capacity || "N/A"}</td>
                        <td>{tenant.bike_capacity || "N/A"}</td>
                        <td>
                          <Button
                            variant="link"
                            size="sm"
                            className="text-primary p-0 me-2"
                            onClick={() => handleEditClick(tenant)}
                          >
                            <i className="bi bi-pencil"></i>
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            className="text-danger p-0"
                            onClick={() => handleDelete(tenant.company_name)}
                          >
                            <i className="bi bi-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {editingTenant && (
        <EditTenant
          tenant={editingTenant}
          onClose={() => setEditingTenant(null)}
          onSave={handleSave}
        />
      )}
    </Container>
  );
};

export default ManageTenant;
