import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

const AddTag = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("2W");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [officeList, setOfficeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isExcelUpload, setIsExcelUpload] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch office list
  useEffect(() => {
    const fetchOffices = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        Swal.fire({
          icon: "warning",
          title: "Unauthorized",
          text: "Please log in.",
        });
        return;
      }

      try {
        const officeResponse = await axios.get("http://localhost:8000/api/getoffice", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOfficeList(officeResponse.data?.office || []);
      } catch (error) {
        console.error("Error fetching offices:", error.response?.data || error.message);
      }
    };

    fetchOffices();
  }, []);

  // Fetch users when tenant changes
  useEffect(() => {
    if (!tenantName) return;

    const fetchUsers = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        Swal.fire({
          icon: "warning",
          title: "Unauthorized",
          text: "Please log in.",
        });
        return;
      }

      try {
        const userResponse = await axios.get(
          `http://localhost:8000/api/user/getUserSpecificOffice/${tenantName}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setUsers(userResponse.data?.data || []);
      } catch (error) {
        console.error("Error fetching users:", error.response?.data || error.message);
      }
    };

    fetchUsers();
  }, [tenantName]);

  // Validate manual form inputs
  const validateForm = () => {
    const newErrors = {};
    if (!tenantName) newErrors.tenantName = "Tenant is required.";
    if (!selectedUser) newErrors.selectedUser = "User is required.";
    if (!cardNumber || cardNumber.length !== 10)
      newErrors.cardNumber = "Card number must be exactly 10 digits.";
    if (!vehicleNumber) newErrors.vehicleNumber = "Vehicle number is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Excel file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryString = event.target.result;
        const workbook = XLSX.read(binaryString, { type: "binary" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        if (jsonData.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Invalid File",
            text: "The uploaded Excel file is empty.",
          });
        } else {
          setFileData(jsonData);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Validate form based on input method
    if (isExcelUpload) {
      if (!fileData.length) {
        Swal.fire({
          icon: "error",
          title: "No Data",
          text: "Please upload a valid Excel file.",
        });
        setIsSubmitting(false);
        return;
      }
    } else {
      if (!validateForm()) {
        setIsSubmitting(false);
        return;
      }
    }
    const payload = isExcelUpload
      ? fileData.map((data) => ({
          username: data.username,
          card_number: data.card_number,
          vehicle_type: data.vehicle_type,
          vehicle_number: data.vehicle_number,
          block_status: false,
        }))
      : [
          {
            username: selectedUser,
            card_number: cardNumber,
            vehicle_type: vehicleType,
            vehicle_number: vehicleNumber,
            block_status: false,
          },
        ];
        const requestBody = {
          office_id: tenantName, // Assuming tenantName represents office_id
          users: payload,        // Pass the payload array here
        };
    try {
      const token = localStorage.getItem("token");
      await axios.post("http://localhost:8000/api/create-tag", requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Tags added successfully.",
      });

      setSelectedUser("");
      setCardNumber("");
      setVehicleType("2W");
      setVehicleNumber("");
      setTenantName("");
      setFileData([]);
      setErrors({});
    } catch (error) {
      console.error("Submission error:", error.response?.data || error.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "Failed to add tags.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container fluid className="mt-4">
      <h2>Add Tag Details</h2>
      <div className="card p-4">
        <Form onSubmit={handleSubmit}>
          {/* Input Method Selection */}
          <Form.Group className="mb-3">
            <Form.Label>Select Input Method</Form.Label>
            <Row>
              <Col xs={6}>
                <Form.Check
                  type="radio"
                  label="Fill Form Manually"
                  name="method"
                  checked={!isExcelUpload}
                  onChange={() => setIsExcelUpload(false)}
                />
              </Col>
              <Col xs={6}>
                <Form.Check
                  type="radio"
                  label="Upload Excel File"
                  name="method"
                  checked={isExcelUpload}
                  onChange={() => setIsExcelUpload(true)}
                />
              </Col>
            </Row>
          </Form.Group>

          {/* Tenant Selection */}
          <Form.Group className="mb-3">
            <Form.Label>Tenant Name</Form.Label>
            <Form.Control
              as="select"
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
            >
              <option value="">Select Tenant</option>
              {officeList.map((office) => (
                <option key={office.office_id} value={office.office_id}>
                  {office.office_name}
                </option>
              ))}
            </Form.Control>
            {errors.tenantName && <Alert variant="danger">{errors.tenantName}</Alert>}
          </Form.Group>

          {/* File Upload */}
          {isExcelUpload && (
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Upload Excel File</Form.Label>
              <Form.Control type="file" accept=".xlsx,.xls" onChange={handleFileUpload} />
            </Form.Group>
          )}

          {/* Manual Form Inputs */}
          {!isExcelUpload && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>User</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}
                >
                  <option value="">Select User</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </Form.Control>
                {errors.selectedUser && <Alert variant="danger">{errors.selectedUser}</Alert>}
              </Form.Group>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="10"
                      value={cardNumber}
                      onChange={(e) => setCardNumber(e.target.value)}
                    />
                    {errors.cardNumber && <Alert variant="danger">{errors.cardNumber}</Alert>}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Vehicle Number</Form.Label>
                    <Form.Control
                      type="text"
                      value={vehicleNumber}
                      onChange={(e) => setVehicleNumber(e.target.value.toUpperCase())}
                    />
                    {errors.vehicleNumber && <Alert variant="danger">{errors.vehicleNumber}</Alert>}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>Vehicle Type</Form.Label>
                <Form.Control
                  as="select"
                  value={vehicleType}
                  onChange={(e) => setVehicleType(e.target.value)}
                >
                  <option value="2W">2 Wheeler</option>
                  <option value="4W">4 Wheeler</option>
                </Form.Control>
              </Form.Group>
            </>
          )}

          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default AddTag;
