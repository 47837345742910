import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const AddAdmin = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [mode, setMode] = useState("manual"); // manual or excel
  const [excelFile, setExcelFile] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get(
          "http://localhost:8000/api/getuserbyrole",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.status === "success") {
          const assignableRoles = response.data.data[0]?.assignableRoles || [];
          setRoles(assignableRoles);
        } else {
          console.error("Error fetching roles:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching roles:", error.response?.data || error.message);
      }
    };

    const fetchOffices = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get(
          "http://localhost:8000/api/getoffice",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data && response.data.office) {
          const officeData = Array.isArray(response.data.office)
            ? response.data.office
            : [response.data.office];
          setOffices(officeData);
        } else {
          console.error("Error fetching offices:", response.data.msg);
        }
      } catch (error) {
        console.error("Error fetching offices:", error.response?.data || error.message);
      }
    };

    fetchRoles();
    fetchOffices();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (mode === "manual" && (!selectedRole || !selectedOffice || !name || !mobileNo)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all the required fields.",
        confirmButtonText: "OK",
      });
      return;
    }

// Start submitting
// const newAdminData = {
//   role_id: selectedRole,
//   office_id: selectedOffice,
//   users: fileData.map((user) => ({
//     username: user.username,
//     password: String(user.password), // Ensure password is a string
//     name: user.name,
//     mobile_no: user.mobile_no,
//   })),
// };

    let newAdminData = {};

    if (mode === "manual") {
      newAdminData = {
        role_id: selectedRole,
        office_id: selectedOffice,
        username: selectedRole !== "Employee" ? username : undefined,
        password: selectedRole !== "Employee" ? password : undefined,
        name,
        mobile_no: mobileNo,
      };
    } else if (mode === "excel" && excelFile) {
      newAdminData = { file: excelFile };
    }

    try {
      const response = await axios.post(
        mode === "manual"
          ? "http://localhost:8000/api/create/user"
          : "http://localhost:8000/api/upload/excel",
        newAdminData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": mode === "excel" ? "multipart/form-data" : "application/json",
          },
        }
      );

      if (response.data && response.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Form submitted successfully!",
          text: "The admin has been added.",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error submitting form!",
          text: response.data.msg || "An unexpected error occurred.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error submitting form!",
        text: error.message || "An unexpected error occurred.",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Container fluid className="mt-4">
      <h2 className="mb-4">Admin Details Section</h2>
      <p>Register new admin</p>
      <div className="card" style={{ minHeight: "84vh" }}>
        <Form
          onSubmit={handleSubmit}
          style={{ width: "90%", marginLeft: "55px", marginTop: "30px" }}
        >
          <h3>Input Selection</h3>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                label="Manual Entry"
                name="mode"
                id="manualMode"
                value="manual"
                checked={mode === "manual"}
                onChange={() => setMode("manual")}
              />
            </Col>
            <Col>
              <Form.Check
                type="radio"
                label="Upload Excel"
                name="mode"
                id="excelMode"
                value="excel"
                checked={mode === "excel"}
                onChange={() => setMode("excel")}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <Form.Group controlId="selectTenant">
                <Form.Label>
                  Select Tenant<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedOffice}
                  onChange={(e) => setSelectedOffice(e.target.value)}
                >
                  <option value="">Select Tenant</option>
                  {offices.map((office) => (
                    <option key={office.office_id} value={office.office_id}>
                      {office.office_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="selectRole">
                <Form.Label>
                  Select Role<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.role_id} value={role.role_id}>
                      {role.role_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {mode === "manual" && (
            <>
              <Form.Group controlId="formName" className="mt-4">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              {selectedRole !== "4" && (
                <>
                  <Form.Group controlId="formUsername" className="mt-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formPassword" className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group controlId="formMobile" className="mt-3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </Form.Group>
            </>
          )}

          {mode === "excel" && (
            <Form.Group controlId="formExcelFile" className="mt-4">
              <Form.Label>Upload Excel File</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setExcelFile(e.target.files[0])}
              />
            </Form.Group>
          )}

          <Button variant="primary" type="submit" className="mt-4">
            Submit
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default AddAdmin;
