import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  const [showForm, setShowForm] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const [ticketDetails, setTicketDetails] = useState(null);

  const handleSubmit = () => {
    // Fetch ticket details based on the ticket number
    // Here you can replace with actual data fetching logic
    const details = {
      number: ticketNumber,
      description: "Sample ticket description",
      status: "Open",
    };
    setTicketDetails(details);
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
    setTicketNumber("");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          style={{
            height: "80px",
            backgroundColor: "#FFFFFF",
            marginTop: "-8.5px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center mt-1">
            <h4 className="adminlogo">
              Welcome back, Skymark - Parking management system
            </h4>
            <img style={{ height: "70px" }} src="adminlogo.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
