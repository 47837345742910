import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Liveactivitylogs from "./Liveactivitylogs"; // Import the updated component
import GraphDataindashboard from "./GraphDataindashboard";
import bike from "../../Tenant/Collections/bike.jpg";
import car from "../../Tenant/Collections/car.jpg";
import parkingexit from "../../Tenant/Collections/parkingexit.jpg";
import parkingentry from "../../Tenant/Collections/parkingnetry.png";
import openbarrier from "../../Tenant/Collections/openbarriar.png";
import closebarrier from "../../Tenant/Collections/Parki.png";
import lastentryvehile from "../../Tenant/Collections/lastentryvehile.jpg";
import Header from './Header'

const Dashboard1 = () => {
  const [entryExitdata, setEntryExitdata] = useState({
    entry: 0,
    exits: 0,
    todayCarEntrycount: 0,
    todayCarExitscount: 0,
    todayBikeEntrycount: 0,
    todayBikeExitscount: 0,
    lastEntryVehicleNumber: "",
    lastExitVehicleNumber: "",
  });

  const [officeId, setOfficeId] = useState(localStorage.getItem("office_id") || "");

  const [data, setData] = useState({
    totalCapacity: 0,
    occupied: 0,
    percentage: 0,
  });

  useEffect(() => {
    const fetchEntryExitData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!officeId) {
          console.error("Office ID not set.");
          return;
        }
        if (!token) {
          console.error("Token not found in localStorage.");
          return;
        }
        const response = await axios.get(
          `http://localhost:8000/api/gettotal-entry-exits/${officeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setEntryExitdata({
          ...response.data,
          lastEntryVehicleNumber: response.data.lastEntry?.Vehicle?.vehicle_number || "N/A",
          lastExitVehicleNumber: response.data.lastExits?.Vehicle?.vehicle_number || "N/A",
        });
        console.log("Fetched entry/exit data:", response.data);
      } catch (error) {
        console.error("Error fetching entry/exit data:", error);
      }
    };

    const interval = setInterval(fetchEntryExitData, 1000);
    return () => clearInterval(interval);
  }, [officeId]); // Add officeId as a dependency

  useEffect(() => {
    const fetchTodayCapacity = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!officeId) {
          console.error("Office ID not set.");
          return;
        }
        if (!token) {
          console.error("Token not found in localStorage.");
          return;
        }
        const response = await axios.get(
          `http://localhost:8000/api/gettotal-accupied/${officeId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Fetched capacity data:", response.data);

     
        if (response.data && response.data.totalOccupied.length > 0) {
          const {
            total_occupied: totalOccupied,
            total_car_capacity: carCapacity,
            total_bike_capacity: bikeCapacity,
          } = response.data.totalOccupied[0];

          const totalCapacity = parseInt(carCapacity) + parseInt(bikeCapacity);
          const occupied = totalOccupied;
          const percentage = (occupied / totalCapacity) * 100;

          setData({ totalCapacity, occupied, percentage });
        }
      } catch (error) {
        console.error("Error fetching capacity data:", error);
      }
    };

    const intervalId = setInterval(fetchTodayCapacity, 1000);
    return () => clearInterval(intervalId);
  }, [officeId]); // Add officeId as a dependency
  
  const graphData = {
      todayCarEntrycount: entryExitdata.todayCarEntrycount,
      todayCarExitscount: entryExitdata.todayCarExitscount,
      todayBikeEntrycount: entryExitdata.todayBikeEntrycount,
      todayBikeExitscount: entryExitdata.todayBikeExitscount,
  };
  
  return (

    <div className="container mt-3">
        <div>
        <Header setOfficeId={setOfficeId} />
</div>

      <div className="row mt-3">
        <div className="col-md-4">
          <div
            className="p-3 border bg-white d-flex flex-column align-items-center justify-content-center"
            style={{
              position: "relative",
              height: "400px",
              borderWidth: "10px",
              borderRadius: "10px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
            }}
          >
            <h4
              style={{
                position: "absolute",
                left: "12%",
                top: "10%",
                margin: 0,
              }}
            >
              Total Capacity: {data.totalCapacity}
            </h4>
            <div
              className="circle"
              style={{ width: "100%", maxWidth: "240px", marginTop: "30px" }}
            >
              <CircularProgressbar
                value={data.percentage}
                text={`${data.percentage.toFixed(1)}%`}
                styles={buildStyles({
                  pathColor: "#FD995F", // Orange color for Central Card
                  trailColor: "rgba(0, 128, 0, 1)", // Dark green for background
                  textColor: "black",
                  backgroundColor: "#3e98c7",
                })}
              />
            </div>
          </div>

          {/* New row with two columns */}
          <div className="row mt-3">
            <div className="col-6">
              <div
                className="p-3 border bg-white text-dark"
                style={{
                  height: "200px",
                  borderWidth: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Total Bike Today
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    marginTop: "40px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#007bff",
                      margin: "0 20px 0 0",
                    }}
                  >
                    {entryExitdata.todayBikeEntrycount}
                  </p>
                  <img
                    src={bike}
                    alt="Unknown Icon"
                    style={{ width: "80px", marginLeft: "20px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                className="p-3 border bg-white text-dark"
                style={{
                  height: "200px",
                  borderWidth: "10px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Total Car Today
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "2px",
                    marginTop: "40px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#007bff",
                      margin: "0 20px 0 0",
                    }}
                  >
                    {entryExitdata.todayCarEntrycount}
                  </p>
                  <img
                    src={car}
                    alt="wrong gate Icon"
                    style={{ width: "80px", marginLeft: "20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          {/* Existing nested row */}
          <div className="row">
            <div className="col-6 col-md-3">
              <div
                className="p-3 border bg-white text-dark mb-3 mb-md-0"
                style={{
                  borderWidth: "10px",
                  borderRadius: "10px",
                  height: "180px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h4
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Total Entry Today
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#007bff",
                      margin: "0 20px 0 0", // Adjusted margin-right for desired distance
                    }}
                  >
                    {entryExitdata.entry}
                  </p>
                  <img
                    src={openbarrier}
                    alt="Scan Icon"
                    style={{ width: "100px", marginLeft: "20px" }}
                  />
                  {/* Adjusted margin-left for desired distance */}
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div
                className="p-3 border bg-white text-dark mb-3 mb-md-0"
                style={{
                  borderWidth: "10px",
                  borderRadius: "10px",
                  height: "180px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h4
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Total Exit Today
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#007bff",
                      margin: "0 20px 0 0", // Adjusted margin-right for desired distance
                    }}
                  >
                    {entryExitdata.exits}
                  </p>
                  <img
                    src={closebarrier}
                    alt="Scan Icon"
                    style={{ width: "100px", marginLeft: "20px" }}
                  />{" "}
                  {/* Adjusted margin-left for desired distance */}
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div
                className="p-3 border text-dark mb-3 mb-md-0"
                style={{
                  backgroundColor: "#22CA79",
                  borderWidth: "10px",
                  borderRadius: "10px",
                  height: "180px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h4
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Last Car Entered
                </h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {entryExitdata.lastEntryVehicleNumber}
                  </p>
                  <img
                    src={lastentryvehile}
                    alt="Scan Icon"
                    style={{ width: "120px" }}
                  />
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3">
              <div
                className="p-3 border text-dark mb-3 mb-md-0"
                style={{
                  backgroundColor: "#FD995F", // Updated background color
                  borderWidth: "10px",
                  borderRadius: "10px",
                  height: "180px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow =
                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow =
                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                }}
              >
                <h4
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "white", // Ensure text is visible on new background
                  }}
                >
                  Last Car Exit
                </h4>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Center-aligns content horizontally
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {entryExitdata.lastExitVehicleNumber}
                    </p>
                    <img
                      src={lastentryvehile}
                      alt="Scan Icon"
                      style={{ width: "120px" }}
                    />
                  </div>
                  {/* Adjusted margin-left for desired distance */}
                </div>
              </div>
            </div>
          </div>

          {/* New row with col-12 */}
          <div className="row mt-3">
            <div className="col-12">
              <div
                className="p-3 border bg-white text-dark"
                style={{
                  minHeight: "300px",
                  borderWidth: "10px",
                  borderRadius: "10px",
                }}
              >
                <h5>Car and Bike Entry Exit</h5>
                <p>
                  From the time scanning will start till the time of scanning
                  end
                </p>
                <GraphDataindashboard graphData={graphData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Liveactivitylogs below both sections */}
      <div className="row mt-3">
        <div className="col-12">
        <Liveactivitylogs officeId={officeId} graphData={graphData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard1;
