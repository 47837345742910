import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const Addadmin = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get(
          "http://localhost:8000/api/getuserbyrole",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data.status === "success") {
          const assignableRoles = response.data.data[0]?.assignableRoles || [];
          setRoles(assignableRoles);
        } else {
          console.error("Error fetching roles:", response.data.msg);
        }
      } catch (error) {
        console.error(
          "Error fetching roles:",
          error.response?.data || error.message
        );
      }
    };

    const fetchOffices = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get(
          "http://localhost:8000/api/getoffice",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (response.data && response.data.office) {
          const officeData = Array.isArray(response.data.office)
            ? response.data.office
            : [response.data.office];
          setOffices(officeData);
        } else {
          console.error("Error fetching offices:", response.data.msg);
        }
      } catch (error) {
        console.error(
          "Error fetching offices:",
          error.response?.data || error.message
        );
      }
    };

    fetchRoles();
    fetchOffices();
  }, []);

  useEffect(() => {
    // Update selected role name dynamically when the selected role changes
    const roleName = roles.find((role) => role.role_id === selectedRole)?.role_name || "";
    alert(roleName); // This will now show the alert correctly whenever selectedRole changes
    setSelectedRoleName(roleName);
  }, [selectedRole, roles]); // Add selectedRole and roles as dependencies
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newAdminData = {
      role_id: selectedRole,
      office_id: selectedOffice,
      username,
      password,
      name,
      mobile_no: mobileNo,
    };

    try {
      const response = await axios.post(
        "http://localhost:8000/api/create/user",
        newAdminData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data && response.data.status === "success") {
          // Show SweetAlert on success
      Swal.fire({
        icon: 'success',
        title: 'Form submitted successfully!',
        text: 'The tag has been added.',
        confirmButtonText: 'OK'
      });

      } else {
        console.log("Response:", response.data); // Log to check the response
         // Show SweetAlert on error
      Swal.fire({
        icon: 'error',
        title: 'Error submitting form!',
        text: errorMessage,
        confirmButtonText: 'OK'
      });

      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
        // Show SweetAlert on error
        Swal.fire({
          icon: 'error',
          title: 'Error submitting form!',
          text: errorMessage,
          confirmButtonText: 'OK'
        });

    }
  };

  return (
    <Container fluid className="mt-4">
      <h2 className="mb-4">Admin Details Section</h2>
      <p>Register new admin</p>
      <div style={{ minHeight: "84vh" }} className="card">
        <Form
          onSubmit={handleSubmit}
          style={{ width: "90%", marginLeft: "55px" }}
        >
          <Row className="mt-4" style={{ gap: "20px" }}>
            <Row style={{ marginTop: "10px" }}>
              <Col xs={6}>
                <Form.Group controlId="tenantRole" className="mb-4">
                  <h4>Enter the details of admin</h4>
                  <Form.Label className="mb-4">
                    Select Tenant<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedOffice}
                    onChange={(e) => setSelectedOffice(e.target.value)}
                  >
                    <option value="">Select Tenant</option>
                    {offices.map((office) => (
                      <option key={office.office_id} value={office.office_id}>
                        {office.office_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group controlId="roleSelect" className="mb-4">
                  <Form.Label style={{ marginTop: "38px" }} className="mb-4">
                    Select Role<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.role_id} value={role.role_id}>
                        {role.role_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Col xs={12}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>

            {/* Dynamically hide these fields based on role */}
            {selectedRoleName != "Employee" && (
              <>
                <Col xs={12}>
                  <Form.Group controlId="formUsername">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter User Name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col xs={12}>
                  <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </>
            )}

            <Col xs={12}>
              <Form.Group controlId="formMobileNumber">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12} className="d-flex justify-content-start">
              <Button variant="primary" type="submit" className="me-4">
                Submit
              </Button>
              <Button variant="secondary" type="button">
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default Addadmin;
