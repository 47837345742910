import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx"; // Import xlsx
import axios from "axios";

const Report = () => {
  const [reportData, setReportData] = useState([]); // Holds API data
  const [filteredData, setFilteredData] = useState([]); // Data filtered by user input
  const [tenants, setTenants] = useState([]); // List of tenants
  const [report, setReport] = useState(""); // Selected report type
  const [formValues, setFormValues] = useState({
    fromDate: null,
    toDate: null,
    tenantId: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Submission status

  // Fetch tenants (offices)
  const fetchTenants = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, please log in.");
        return;
      }
      const response = await axios.get("http://localhost:8000/api/getoffice", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.office) {
        setTenants(response.data.office); // Update tenants state with office data
      } else {
        console.error("Error fetching offices:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching tenants:", error.response?.data || error.message);
    }
  };

  // Fetch entry-exit data
  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!formValues.tenantId || !formValues.fromDate || !formValues.toDate) {
      console.error("Please select all the required fields.");
      return;
    }

    try {
      const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(
          d.getDate()
        ).padStart(2, "0")}`;
      };

      const payload = {
        office_id: formValues.tenantId, // Send the selected tenant (office) ID
        tenant_id: formValues.tenantId,
        report,
        start_date: formatDate(formValues.fromDate),
        end_date: formatDate(formValues.toDate),
      };

      const response = await axios.post(
        "http://localhost:8000/api/get-entry-exit-data",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "success") {
        setReportData(response.data.data);
      } else {
        console.error("Unexpected response format or status:", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Handle form changes
  const handleDateChange = (date, name) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangereport = (e) => {
    setReport(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    fetchData();
  };

  // Export to Excel
  const exportToExcel = () => {
    const columns = ["EN ID", "User Name", "Office Name", "Entry Time", "Exit Time"];
    const rows = filteredData.map((data) => [
      data.en_id,
      data.User?.name || "N/A",
      data.Office?.company_name || "N/A",
      data.entry_datetime ? new Date(data.entry_datetime).toLocaleString() : "N/A",
      data.exits_timedate ? new Date(data.exits_timedate).toLocaleString() : "N/A",
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([columns, ...rows]); // Create worksheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report Data"); // Append the sheet to the workbook
    XLSX.writeFile(workbook, "report_data.xlsx"); // Export to Excel file
  };

  // Filter data based on date range
  useEffect(() => {
    setFilteredData(
      reportData.filter((item) => {
        if (!formValues.fromDate || !formValues.toDate) return true;

        const entryDate = item.entry_datetime ? new Date(item.entry_datetime) : null;
        const exitDate = item.exits_timedate ? new Date(item.exits_timedate) : null;

        const from = new Date(formValues.fromDate);
        const to = new Date(formValues.toDate);

        return (
          (!entryDate || (entryDate >= from && entryDate <= to)) &&
          (!exitDate || (exitDate >= from && exitDate <= to))
        );
      })
    );
  }, [reportData, formValues]);

  useEffect(() => {
    fetchTenants();
  }, []);

  return (
    <Container fluid style={{ marginTop: "22px" }}>
      <div className="d-flex justify-content-between align-items-center">
        <h2>Entry Tickets History Reports</h2>
        <Button variant="danger" onClick={exportToExcel} disabled={filteredData.length === 0}>
          Export to Excel
        </Button>
      </div>
      <p>Report generating accordingly.</p>
      <div className="match-card card p-4">
        <Form onSubmit={handleSubmit}>
          <Row className="align-items-end mb-3">
            <Col md={6}>
              <Form.Group controlId="selectReport">
                <Form.Label>Select Report</Form.Label>
                <Form.Control
                  as="select"
                  name="report"
                  value={report}
                  onChange={handleChangereport}
                >
                  <option value="">Select Report</option>
                  <option value="Entry Report">Entry Report</option>
                  <option value="Exits Report">Exits Report</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="selectTenant">
                <Form.Label>Select Tenant</Form.Label>
                <Form.Control
                  as="select"
                  name="tenantId"
                  value={formValues.tenantId}
                  onChange={handleChange}
                >
                  <option value="">Select Tenant</option>
                  {tenants.map((tenant) => (
                    <option key={tenant.office_id} value={tenant.office_id}>
                      {tenant.office_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="align-items-end">
            <Col>
              <Form.Group controlId="fromDate">
                <Form.Label>From Date</Form.Label>
                <DatePicker
                  selected={formValues.fromDate || null}
                  onChange={(date) => handleDateChange(date, "fromDate")}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="toDate">
                <Form.Label>To Date</Form.Label>
                <DatePicker
                  selected={formValues.toDate || null}
                  onChange={(date) => handleDateChange(date, "toDate")}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                />
              </Form.Group>
            </Col>
            <Col>
              <Button style={{ backgroundColor: "#664CBE" }} variant="primary" type="submit">
                Generate Report
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="mt-4">
        {isSubmitted && filteredData.length === 0 ? (
          <p className="text-center text-danger fs-5">No data available for the selected filters.</p>
        ) : (
          filteredData.length > 0 && (
            <>
              <h5>Report Data</h5>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>EN ID</th>
                    <th>User Name</th>
                    <th>Office Name</th>
                    <th>Entry Time</th>
                    <th>Exit Time</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.en_id}>
                      <td>{item.en_id}</td>
                      <td>{item.User?.name || "N/A"}</td>
                      <td>{item.Office?.company_name || "N/A"}</td>
                      <td>{item.entry_datetime ? new Date(item.entry_datetime).toLocaleString() : "N/A"}</td>
                      <td>{item.exits_timedate ? new Date(item.exits_timedate).toLocaleString() : "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )
        )}
      </div>
    </Container>
  );
};

export default Report;
