import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";

const AddTenant = () => {
  const [tenantName, setTenantName] = useState("");
  const [bikeCapacity, setBikeCapacity] = useState("");
  const [carCapacity, setCarCapacity] = useState("");
  const [errors, setErrors] = useState({});
  const [isExcelUpload, setIsExcelUpload] = useState(false);
  const [excelFile, setExcelFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateForm = () => {
    const newErrors = {};
    if (!tenantName.trim()) newErrors.tenantName = "Tenant Name is required.";
    if (!bikeCapacity) newErrors.bikeCapacity = "Bike Capacity is required.";
    else if (bikeCapacity <= 0)
      newErrors.bikeCapacity = "Bike Capacity must be greater than zero.";
    if (!carCapacity) newErrors.carCapacity = "Car Capacity is required.";
    else if (carCapacity <= 0)
      newErrors.carCapacity = "Car Capacity must be greater than zero.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isExcelUpload) {
      if (!excelFile) {
        alert("Please upload an Excel file.");
        return;
      }
      const formData = new FormData();
      formData.append("file", excelFile);
      try {
        const response = await axios.post(
          "http://localhost:8000/api/create-offices-from-excel",
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data && response.data.status === "success") {
          setSuccessMessage(response.data.message); // Fixed typo 'messege' -> 'message'
          alert(response.data.message);
        } else {
          setErrorMessage(response.data.message); // Fixed typo 'messege' -> 'message'
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error.response?.data || error.message);
        setErrorMessage(error.message); // Display error message
        alert(error.message);
      }
    } else {
      if (!validateForm()) {
        return;
      }

      const newTenantData = {
        company_name: tenantName,
        bike_capacity: bikeCapacity,
        car_capacity: carCapacity,
      };

      try {
        const response = await axios.post(
          "http://localhost:8000/api/create-office",
          newTenantData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data && response.data.status === "success") {
          setSuccessMessage("Tenant created successfully.");
          setTenantName("");
          setBikeCapacity("");
          setCarCapacity("");
          setErrors({});
        } else {
          setErrorMessage(response.data.message);
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error:", error.response?.data || error.message);
        setErrorMessage(error.message);
        alert(error.message);
      }
    }
  };

  return (
    <Container fluid className="mt-4">
      <h2 className="mb-4">Tenant Details Section</h2>
      <p>Register new Tenant</p>

      {successMessage && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage("")}
          dismissible
        >
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert
          variant="danger"
          onClose={() => setErrorMessage("")}
          dismissible
        >
          {errorMessage}
        </Alert>
      )}

      <div style={{ minHeight: "84vh" }} className="card">
        <Form onSubmit={handleSubmit} style={{ width: "90%", marginLeft: "55px" }}>
          <Row className="mt-4" style={{ gap: "20px" }}>
            <Col xs={12}>
              <Form.Group controlId="formMethod">
                <Form.Label>Select Input Method</Form.Label>
                <Row>
                  <Col xs={6}>
                    <Form.Check
                      type="radio"
                      label="Fill Form Manually"
                      name="method"
                      checked={!isExcelUpload}
                      onChange={() => setIsExcelUpload(false)}
                      id="manualForm"
                    />
                  </Col>
                  <Col xs={6}>
                    <Form.Check
                      type="radio"
                      label="Upload Excel File"
                      name="method"
                      checked={isExcelUpload}
                      onChange={() => setIsExcelUpload(true)}
                      id="excelUpload"
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            {!isExcelUpload && (
              <>
                <Row style={{ marginTop: "10px" }}>
                  <h4>Enter the details of admin</h4>
                  <Col xs={12}>
                    <Form.Group controlId="formName">
                      <Form.Label>Tenant Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Tenant Name"
                        value={tenantName}
                        onChange={(e) => setTenantName(e.target.value)}
                      />
                      {errors.tenantName && (
                        <small className="text-danger">{errors.tenantName}</small>
                      )}
                    </Form.Group>
                  </Col>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <Form.Group controlId="tenantRole" className="mb-4">
                        <Form.Label>
                          Bike Capacity<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Bike Capacity"
                          value={bikeCapacity}
                          onChange={(e) => setBikeCapacity(e.target.value)}
                        />
                        {errors.bikeCapacity && (
                          <small className="text-danger">{errors.bikeCapacity}</small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="roleSelect" className="mb-4">
                        <Form.Label>
                          Car Capacity<span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Car Capacity"
                          value={carCapacity}
                          onChange={(e) => setCarCapacity(e.target.value)}
                        />
                        {errors.carCapacity && (
                          <small className="text-danger">{errors.carCapacity}</small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </>
            )}

            {isExcelUpload && (
              <Col xs={12}>
                <Form.Group controlId="formFile">
                  <Form.Label>Upload Excel File</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    onChange={(e) => setExcelFile(e.target.files[0])}
                  />
                </Form.Group>
              </Col>
            )}

            <Row className="mt-3">
              <Col xs={12} className="d-flex justify-content-start">
                <Button variant="primary" type="submit" className="me-4">
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    setTenantName("");
                    setBikeCapacity("");
                    setCarCapacity("");
                    setErrors({});
                    setExcelFile(null); // Reset file input
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default AddTenant;
