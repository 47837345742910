import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Breadcrumb,
  Button,
  Card,
  Modal,
  Form,
  Pagination,
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx"; // Import xlsx library
import { saveAs } from "file-saver"; // Import file-saver

const ManageTag = () => {
  const [records, setRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tenantName, setTenantName] = useState("");
  const [officeList, setOfficeList] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    vehicle_number: "",
    vehicle_type: "",
    block_status: "",
    userName: "",
    card_no: "",
  });

  const itemsPerPage = 10;

  // Fetch records on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://localhost:8000/api/get-tags/${tenantName}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = response.data;

        if (data.status === "success") {
          setRecords(data.data); // Assuming data.data contains the tags
        } else {
          console.error("Data is not in the expected format:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tenantName]);

  // Second useEffect for fetching office data
  useEffect(() => {
    const fetchAllOffices = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found, please log in.");
          return;
        }

        const response = await axios.get("http://localhost:8000/api/getoffice", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data && response.data.office) {
          const officeData = Array.isArray(response.data.office)
            ? response.data.office
            : [response.data.office];
          setOfficeList(officeData);
          console.log(officeData);
        } else {
          console.error("Error fetching offices:", response.data.msg);
        }
      } catch (error) {
        console.error(
          "Error fetching all offices:",
          error.response?.data || error.message
        );
      }
    };

    fetchAllOffices(); // Fetch office data on component mount
  }, []);

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setFormData({
      vehicle_number: record.vehicle_number,
      vehicle_type: record.vehicle_type,
      block_status: record.block_status,
      userName: record.userName,
      card_no: record.card_no,
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };

  const handleSaveChanges = async () => {
    const updatedData = {
      ...formData,
      office_id: localStorage.getItem("office_id"),
    };

    try {
      const response = await axios.post(
        `http://localhost:8000/api/edit-tag/${selectedRecord.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.status === "success") {
        setRecords((prevRecords) =>
          prevRecords.map((record) =>
            record.id === selectedRecord.id ? { ...record, ...updatedData } : record
          )
        );
        setShowModal(false); // Close modal after success
      } else {
        console.error("Error in response:", response.data);
      }
    } catch (error) {
      console.error("Error updating record:", error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.post(
        `http://localhost:8000/api/delete-tag/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.status === "success") {
        setRecords(records.filter((record) => record.id !== id));
      }
    } catch (error) {
      console.error("Error deleting record:", error);
    }
  };

  // Handle Excel download
  const handleDownloadExcel = () => {
    if (records.length === 0) {
      return; // Do nothing if no records
    }

    const ws = XLSX.utils.json_to_sheet(
      records.map((record, index) => ({
        "#": index + 1,
        "User": record.User.name,
        "Vehicle No": record.vehicle_number,
        "Type": record.vehicle_type,
        "Status": record.block_status,
        "Card No": record.card_no,
        "Date Time": new Date(record.createdAt).toLocaleString(),
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Registered Users");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const file = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(file, "ManageTags.xlsx");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);

  const totalPages = Math.ceil(records.length / itemsPerPage);

  return (
    <Container fluid className="p-4" style={{ minHeight: "100vh" }}>
      <Row className="mb-3">
        <Col xs={12} lg={8} className="mb-2 mb-lg-0">
          <h2>Manage Registered Users and Assign the Cards</h2>
          <p>All registered Users with all details</p>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="d-flex justify-content-lg-end align-items-center"
        >
          <Button
            variant="primary"
            onClick={handleDownloadExcel}
            disabled={records.length === 0} // Disable if no records
          >
            Download Excel <i className="bi bi-download"></i>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Live Logs</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Form.Group controlId="formTenant">
            <Form.Label>Select Tenant</Form.Label>
            <Form.Control
              as="select"
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
            >
              <option value="">Select Tenant</option>
              {officeList.map((office, index) => (
                <option key={index} value={office.office_id}>
                  {office.office_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Card className="mt-3">
            <Card.Header>
              <strong>Manage Registered User and Tag</strong>
            </Card.Header>
            <Card.Body>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Registered User</th>
                    <th>Vehicle Number</th>
                    <th>Vehicle Type</th>
                    <th>Status</th>
                    <th>Card Number</th>
                    <th>Date Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.length > 0 ? (
                    currentRecords.map((record, index) => (
                      <tr key={record.id}>
                        <td>{index + 1 + indexOfFirstRecord}</td>
                        <td>{record.User.name}</td>
                        <td>{record.vehicle_number}</td>
                        <td>{record.vehicle_type}</td>
                        <td>{record.block_status}</td>
                        <td>{record.card_no}</td>
                        <td>{new Date(record.createdAt).toLocaleString()}</td>
                        <td>
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => handleEdit(record)}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </Button>{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDelete(record.id)}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No records found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <Pagination>
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={currentPage === index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Registered User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formVehicleNumber">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control
                type="text"
                name="vehicle_number"
                value={formData.vehicle_number}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formVehicleType">
              <Form.Label>Vehicle Type</Form.Label>
              <Form.Control
                type="text"
                name="vehicle_type"
                value={formData.vehicle_type}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formBlockStatus">
              <Form.Label>Status</Form.Label>
              <Form.Control
                type="text"
                name="block_status"
                value={formData.block_status}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formUserName">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCardNo">
              <Form.Label>Card Number</Form.Label>
              <Form.Control
                type="text"
                name="card_no"
                value={formData.card_no}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ManageTag;
