import React, { useState, useEffect } from "react";
import axios from "axios";

const LiveActivityLogs = ({ officeId, graphData }) => {
  const [logs, setLogs] = useState([]);
  const [isOfficeView, setIsOfficeView] = useState(false);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("Token not found in localStorage.");
          return;
        }

        console.log("officeId value:", officeId);

        let apiUrl;

        if (Number(officeId) === 1) {
          console.log("Fetching all offices...");
          apiUrl = "http://localhost:8000/api/getalloffices/occupied";
          setIsOfficeView(true);
        } else {
          console.log(`Fetching logs for officeId: ${officeId}`);
          apiUrl = `http://localhost:8000/api/getlast10-entry-exits/${officeId}`;
          setIsOfficeView(false);
        }

        const response = await axios.get(apiUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("API Response:", response.data);

        if (Number(officeId) === 1) {
          const officeData = response.data.caroccupied.map((office) => ({
            company_name: office.company_name || "N/A",
            car_capacity: office.car_capacity || "N/A",
            bike_capacity: office.bike_capacity || "N/A",
            car_engaged: office.total_2w_occupied || "0",
            bike_engaged: office.total_4w_occupied || "0",
          }));

          setLogs(officeData);
        } else {
          setLogs(response.data.data || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLogs();
  }, [officeId, graphData]);

  return (
    <div
      style={{
        minHeight: "570px",
        borderRadius: "10px",
        overflowX: "auto",
      }}
      className="p-3 border bg-white text-dark mt-3"
    >
      <h4 className="mb-3">Live Activity Logs</h4>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {isOfficeView ? (
                <>
                  <th style={{ textAlign: "center" }}>Company Name</th>
                  <th style={{ textAlign: "center" }}>Car Capacity</th>
                  <th style={{ textAlign: "center" }}>Bike Capacity</th>
                  <th style={{ textAlign: "center" }}>Car Engaged</th>
                  <th style={{ textAlign: "center" }}>Bike Engaged</th>
                </>
              ) : (
                <>
                  <th style={{ textAlign: "center" }}>Vehicle Number</th>
                  <th style={{ textAlign: "center" }}>Card Number</th>
                  <th style={{ textAlign: "center" }}>Vehicle Type</th>
                  <th style={{ textAlign: "center" }}>Entry DateTime</th>
                  <th style={{ textAlign: "center" }}>Exit DateTime</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {logs.length > 0 ? (
              logs.map((log, index) => (
                <tr key={index}>
                  {isOfficeView ? (
                    <>
                      <td style={{ textAlign: "center" }}>
                        {log.company_name}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.car_capacity}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.bike_capacity}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.car_engaged}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.bike_engaged}
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ textAlign: "center" }}>
                        {log.Vehicle?.vehicle_number || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.Vehicle?.card_no || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.Vehicle?.vehicle_type || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.entry_datetime || "N/A"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {log.exits_timedate || "N/A"}
                      </td>
                    </>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LiveActivityLogs;
